


















































































import ProfessionalLearningAnalyticsComponent from './ProfessionalLearningAnalyticsComponent';
export default ProfessionalLearningAnalyticsComponent;
